import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import "./style.modules.css";

const MobileNumberForm = () => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState('');
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [mobileBlockActive, setMobileBlockActive] = useState(false);

  function enableMobileBlock() {
    setMobileBlockActive(true);
  }

  const handleChange = (e) => {
    const value = e.target.value;
    if (/^\+?[0-9]*$/.test(value)) {
      setMobileNumber(value);
    }
  };

  useEffect(() => {
    
    const mobileNumberPattern = /^[6789]\d{9}$/;
    if (mobileNumberPattern.test(mobileNumber)) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [mobileNumber]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const mobileNumberPattern = /^[6789]\d{9}$/;

    if (!mobileNumberPattern.test(mobileNumber)) {
      setError('Please enter a valid 10-digit mobile number.');
      return;
    }

    setError('');
    setLoading(true);

    try {
      const response = await fetch('https://4vb7qetg8g.execute-api.ap-south-1.amazonaws.com/v1/downloads', {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': "application/json",
        },
        body: JSON.stringify({ "mobileNumber": mobileNumber })
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // const result = await response.json();

      setTimeout(() => {
        setLoading(false);
        setModalVisible(true);
      }, 2000);

    } catch (error) {
      setError('There was a problem while submitting your mobile number. Please try again after some time.');
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    location.reload();
  };
  const reloadBtnClick = () => {
    location.reload();
  }

  return (
    <>
      <div className={`contact-details-container ${modalVisible ?"modal-enabled":""}`}>
        <div className='contact-detail-header'>
          <StaticImage
            src='https://static-perf1.zupee.com/wp-content/uploads/2024/06/real-money-earning-app-zupee-logo.webp'
            alt='logo'
            width={140}
            height={51}
          />
        </div>
        {!mobileBlockActive ?
          <div className='first-block'>
            <StaticImage src="https://static-perf1.zupee.com/wp-content/uploads/2024/08/App-search.webp" alt="icon" className="icon" width={80} height={80} />
            <div className='first-block-heading'>Zupee App nahi mili?</div>
            <div className='first-block-subheading'>Hum aapko call pe help karenge</div>
            <div className='button-block'>
              <button className='primary-btn' onClick={enableMobileBlock}>Yes, Call Me</button>
              <button className='secondary-btn' onClick={reloadBtnClick}>Go to Home</button>
            </div>
          </div>
          :
          <div className={`mobile-no-block ${modalVisible ? "modal-enabled" : ""}`}>
            {!modalVisible ?
              <form onSubmit={handleSubmit}>
                <label htmlFor="mobileNumber" className='label'>Help on call</label>
                <div className='main-content-box'>
                  <input
                    type="tel"
                    placeholder='Enter your Mobile Number'
                    id="mobileNumber"
                    value={mobileNumber}
                    onChange={handleChange}
                    autoFocus
                    maxLength={10}
                  />
                  {error && <p style={{ color: 'red' }}><small>{error}</small></p>}
                  <div className='button-block'>
                    <button className='primary-btn' type="submit" disabled={!isButtonEnabled || loading}>
                      {loading ?
                        <>
                          <span className="loader__spinner"></span>
                          <span>Submitting...</span>
                        </>
                        : "Submit"}
                    </button>
                  </div>
                </div>
              </form>
              :
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={reloadBtnClick}>&times;</span>
                  <StaticImage
                    src="https://static-perf1.zupee.com/wp-content/uploads/2024/08/Call-back.webp"
                    alt="callback icon"
                    className="callback icon"
                    width={80}
                    height={80}
                  />
                  <p className='callback-text'>Hamari team aapko call pe help karegi</p>
                  <button className='primary-btn' onClick={reloadBtnClick}>Okay</button>
                </div>
              </div>
            }
          </div>
        }
      </div>
    </>
  );
};

export default MobileNumberForm;
